/* Global theme transition */
* {
    transition-property: background-color, border-color, color, fill, stroke, box-shadow, transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Exclude certain elements from transition */
.no-transition {
    transition: none !important;
}

/* Smooth backdrop blur transitions */
.backdrop-blur-sm {
    transition: backdrop-filter 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Card hover transitions */
.hover-transform {
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Button transitions */
button:not(.no-transition) {
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Container transitions */
.auth-container {
    transition: background 500ms ease-in-out;
}

/* Card transitions */
.auth-card {
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Input field transitions */
.auth-input {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Button transitions */
.auth-button {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Theme switch animation */
.theme-transition * {
    transition: background-color 400ms ease-in-out,
                border-color 400ms ease-in-out,
                color 300ms ease-in-out,
                transform 300ms ease-in-out,
                box-shadow 300ms ease-in-out !important;
}
