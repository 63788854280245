@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Theme transition variables */
:root {
    --theme-transition-duration: 200ms;
}

/* Global theme transitions */
html.transitioning * {
    transition: background-color var(--theme-transition-duration) ease-in-out,
                color var(--theme-transition-duration) ease-in-out,
                border-color var(--theme-transition-duration) ease-in-out,
                box-shadow var(--theme-transition-duration) ease-in-out !important;
}

/* Base styles */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color); /* Primary background color */
    color: var(--text-color); /* Default text color */
    transition: background-color var(--theme-transition-duration) ease-in-out;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animation-delay-200 {
    animation-delay: 0.2s;
}

.animation-delay-400 {
    animation-delay: 0.4s;
}

/* Ensure the app container takes up the full height of the viewport */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Content should take up the remaining space between the header/navbar and the footer */
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Footer should be at the bottom of the viewport when content is short, but pushed down with longer content */
footer {
    background-color: #2d3748; /* Adjust as needed */
    color: white;
    text-align: center;
    padding: 1rem;
}

button:hover {
    background-color: #86C232; /* Hover background color */
}

/* Link styles */
a {
    color: #86C232; /* Link color */
}

a:hover {
    color: #61892F; /* Hover link color */
}

.carousel .control-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Increase the margin-top to push the dots down */
}

.carousel .control-dots .dot {
    background-color: #86C232;
    box-shadow: none;
    width: 10px;
    height: 10px;
}

.carousel .control-dots .dot.selected {
    background-color: #61892F;
}

.hover-effect:hover {
    transform: translateY(-5px); /* subtle hover effect */
    transition: transform 0.3s ease-in-out;
}

.logo-image {
    width: 100%;
    max-width: 300px; /* Adjust the max-width as per your design requirements */
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

@font-face {
    font-family: 'OpenDyslexic';
    src: url('fonts/OpenDyslexic-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* Dyslexia-friendly styles */
.dyslexia-mode {
    font-family: 'OpenDyslexic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
}

.dyslexia-button {
    background: linear-gradient(to right, #1B5E20, #2E7D32) !important;
    color: #FFEB3B !important;
}

.dyslexia-text {
    color: #FFEB3B !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8) !important;
}

.card {
    transition: transform 200ms ease-in-out,
                box-shadow 200ms ease-in-out,
                background-color var(--theme-transition-duration) ease-in-out;
}

input, textarea, select {
    transition: background-color var(--theme-transition-duration) ease-in-out,                border-color var(--theme-transition-duration) ease-in-out;}